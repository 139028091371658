<template>
  <dialogForm
    v-on="$listeners"
    v-model="form"
    width="600"
    :btnText="curTittle"
    :title="curTittle"
    :btnType="btnType"
    :option="option"
    :beforeOpen="beforeOpen"
    @submit="onsubmit"
    @close="onClose"
  >
  </dialogForm>
</template>
<script>
import { newFieldOption as option } from './option'
import templateApi from '@/api/productTemplate/index'

export default {
  props: {
    edit: {
      type: String
    },

    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      option,
      form:{}
    }
  },
  computed: {
    isAdd({ edit }) {
      return !edit
    },
    curTittle({ edit }) {
      return edit ? '编辑' : '新增大类'
    },
    btnType({ edit }) {
      return !edit ? 'primary' : 'text'
    },
    fun({ isAdd }) {
      return isAdd ? templateApi.addCategory : templateApi.updateCategory
    },

  },
  methods: {
    beforeOpen() {
      if (this.isAdd) return true
      const { name, englishName} = this.data
      this.form = {
        name,
        englishName
      }
      return true
    },

    onClose() {
      this.form = {}
    },

    async onsubmit(data, done) {
      await done(this.fun({ ...this.form, id: this.isAdd ? undefined : this.data.id, shopTypeId: this.data.shopTypeId }))
    }
  }
}
</script>
