<template>
  <dialogForm
    v-on="$listeners"
    v-model="form"
    width="600"
    :btnText="curTittle"
    :title="curTittle"
    btnType="text"
    :option="option"
    :beforeOpen="beforeOpen"
    @submit="onsubmit"
    @close="onClose"
  >
  </dialogForm>
</template>
<script>
import { newSonOption as option } from './option'
import templateApi from '@/api/productTemplate/index'

export default {
  props: {
    edit: {
      type: String
    },

    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      option,
      form:{}
    }
  },
  computed: {
    isAdd({ edit }) {
      return !edit
    },
    curTittle({ edit }) {
      return edit ? '编辑' : '新增子类'
    },

    fun({ isAdd }) {
      return isAdd ? templateApi.addCategory : templateApi.updateCategory
    }
  },
  methods: {
    beforeOpen() {
      if (this.isAdd) return true
      const { name, englishName } = this.data
      this.form = {
        name,
        englishName
      }
      return true
    },

    onClose() {
      this.form = {}
    },

    async onsubmit(data, done) {
      let parmas = this.isAdd
        ? {
            parentId: this.data.id,
            ...data
          }
        : {
            id: this.data.id,
            ...data
          }
      // return done(false)
      await done(this.fun(parmas))
    }
  }
}
</script>
