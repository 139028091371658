<template>
  <div class="app-container" v-loading="loading">
    <baseTable ref="table" :list="list" :option="option" :isInit="false" initOnTabChange>
      <template #menuLeft>
        <!-- 新增大类 -->
        <newField v-if="childPermission.newField" :data="{ shopTypeId: curTabItem.value }" @success="init" />
      </template>
      <template #menu="{ row }">
        <!-- 新增小类 -->
        <newSon v-if="childPermission.newSon && row.$level < 2" @success="init" :data="row"/>
        <!-- 模板管理 -->
        <color-text-btn v-if="childPermission.templateManagement && row.$level == 2" @click="templateManagement(row)"
          >模板管理</color-text-btn
        >
         <!-- 小类编辑 -->
         <newSon v-if="childPermission.newSon && row.parentId != 0 " :edit="'edit'" @success="init" :data="row"/>
        <!-- 大类编辑 -->
        <newField v-if="childPermission.edit && row.parentId== 0" :data="row" :edit="'edit'" @success="init" />
        <loadingBtn v-if="childPermission.del" type="text" @click="onDelete(row)">删除</loadingBtn>
      </template>
    </baseTable>
  </div>
</template>

<script>
import getBaseTableData from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import templateApi from '@/api/productTemplate/index'
import { getPlatformList } from '@/api/shop/manageApi'
import { checkPermission } from '@/utils'
import { option } from './module/option'
import newField from './module/newField'
import newSon from './module/newSon'
import { TYPE_AMAZON_NUM, TYPE_OTHER_NUM } from '@/utils/constant/fieldConst'

export default {
  mixins: [
    baseTable,
    getBaseTableData({
      dataAttrs: {
        curTabItem: {}
      }
    })
  ],
  components: { newField, newSon },
  data() {
    this.api=templateApi.list
    return {
      option,
      loading: false,
      list: [],
      page: 1,
      sup_this: this
    }
  },
  computed: {
    childPermission({ curTabItem }) {
      return $GET(curTabItem, 'childPermission', {})
    }
  },

  async created() {
    this.loading = true
    const res = await awaitResolve(getPlatformList({}))
    if (!res) return (this.loading = false)
    const { detail } = res

    const arr = res?.detail.filter(({code})=>
      [ TYPE_AMAZON_NUM].includes(Number(code))
    )
    const mappingList = {
      api:{
        [TYPE_AMAZON_NUM]: templateApi.list
      }
    }

    arr.forEach(({ name, code, id }) => {
      this.list.push({
        label: name,
        value: id,
        prop: name,
        // permission: checkPermission('externaladmin:productTemplate:template:list'),
        childPermission: {
          edit: checkPermission('externaladmin:productTemplate:template:edit'),
          del: checkPermission('externaladmin:productTemplate:template:del'),
          newField: checkPermission('externaladmin:productTemplate:template:newField'),
          newSon: checkPermission('externaladmin:productTemplate:template:newSon'),
          templateManagement: checkPermission('externaladmin:productTemplate:template:templateManagement')
        },
        getList: mappingList.api[code],
        resetMergeData: {
          shopTypeId: id,
          orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
        },
      })
    })
    this.loading = false
  },

  methods: {
    getList(postData) {
      return templateApi.list({
        ...postData,
        page: {
          pageIndex: 1,
          pageSize: 0
        }
      })
    },
    handleTableData(data) {
      return this.setRowLevel(data)
    },
    setRowLevel(data, parentLevel = 0,parent=0) {
      let curLevel = ++parentLevel
      data.forEach((row) => {
        row.$level = curLevel
        row.children = row.childList
        // if(parent==0){ 
        //   row.createTime = ''
        // }
        if (row.childList) this.setRowLevel(row.childList, curLevel,1)
      })
      return data
    },

    templateManagement(data) {
      this.$router.push({
        path: 'templateManage',
        query: {
          categoryId: data.id,
          shopTypeId: this.curTabItem.value
        }
      })
    },

    async onDelete(data) {
      try {
        const res = await this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (res != 'confirm') return
        const result = await awaitResolve(
          templateApi.delTemplate({
            idList:[data.id]
          })
        )
        if (!result) return 
        this.init()
        this.$message.success('删除成功')
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
