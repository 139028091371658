import { requireFun } from '@/utils'
import { is1And60char, isIntegerAndZero } from '@/utils/validate'
export const option = {
  topPage: false,
  editBtn: false,
  delBtn: false,
  height: '100%',
  border: true,
  rowKey: 'id',
  defaultExpandAll: true,
  column: [
    {
      label: '商品名称',
      prop: 'name',
    },
    {
      label: '时间',
      prop: 'createTime'
    }
  ]
}

export const newFieldOption = {
  menuAlign: 'left',
  menu: true,
  addBtn: true,
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  formWidth: '600px',
  labelWidth: '110px',
  labelPosition:'left',
  column: [
    {
      label: '大类中文名称',
      prop: 'name',
      span: 22,
      rules: [
        {
          required: true,
          message: requireFun('大类中文名称')
        },
        {
          validator:is1And60char
        }
      ]
    },
    {
      label: '大类英文名称',
      prop: 'englishName',
      span: 22,
      rules: [
        {
          required: true,
          message: requireFun('大类英文名称')
        },
        {
          validator:is1And60char
        }
      ]
    },
    // {
    //   label: '排序',
    //   prop: 'sortNumber',
    //   span: 22,
    //   rules: [
    //     {
    //       required: true,
    //       message: requireFun('排序')
    //     },
    //     {
    //       validator:is1And60char
    //     },
    //     {
    //       validator:isIntegerAndZero
    //     }
    //   ]
    // }
  ]
}
export const newSonOption = {
  menuAlign: 'left',
  menu: true,
  addBtn: true,
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  formWidth: '600px',
  labelWidth: '110px',
  labelPosition:'left',
  column: [
    {
      label: '小类中文名称',
      prop: 'name',
      span: 22,
      rules: [
        {
          required: true,
          message: requireFun('小类中文名称')
        },
        {
          validator:is1And60char
        }
      ]
    },
    {
      label: '小类英文名称',
      prop: 'englishName',
      span: 22,
      rules: [
        {
          required: true,
          message: requireFun('小类英文名称')
        },
        {
          validator:is1And60char
        },
      ]
    },
    // {
    //   label: '排序',
    //   prop: 'sortNumber',
    //   span: 22,
    //   rules: [
    //     {
    //       required: true,
    //       message: requireFun('排序')
    //     },
    //     {
    //       validator:is1And60char
    //     },
    //     {
    //       validator:isIntegerAndZero
    //     }
    //   ]
    // }
  ]
}
