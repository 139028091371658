import { apiFactory } from '@/utils/constant'

const templateApi = {
  list:'/productService/productTemplate/categoryList', // 列表 
  addCategory:'/productService/productTemplate/addCategory',//列表-新增分类/新增子类
  updateCategory:'/productService/productTemplate/updateCategory',//列表-编辑
  delTemplate:'/productService/productTemplate/delCategory',//列表-删除

  templateList:'/productService/productTemplate/templateList', //详情-列表
  importTemplate: '/productService/productTemplate/importTemplate',  // 产品模板 - 上传模板
  uploadTemplate: '/productService/productTemplate/uploadTemplate',  // 产品模板 - 上传模板二进制文件
  disable: '/productService/productTemplate/updateTemplate', // 详情 - 禁用/启用
  del: '/productService/productTemplate/delTemplate', // 详情 - 删除
  
  analysisResult: '/productService/productTemplate/analysisResult', // 产品模板-解析结果
  
  isRequiredUpdate: '/productService/productTemplate/isRequiredUpdate' // 产品模板 - 修改字段是否必填
}

for (const key in templateApi) {
  templateApi[key] = apiFactory(templateApi[key])
}

export default templateApi